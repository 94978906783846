<div class="body background-grey">  
    <div class="description">
        <div class="mobile">
                <img class="rounded" src="assets/frene/entree.jpg" alt="Entree Frêne">
        </div>
        <div class="firstPara">
            <p>
                Le gîte du Frêne fait partie avec son voisin le Fayard d’un corps de bâtiment proche de notre propre habitation.
                Rénové par nos soins dans le souci du respect environnemental, il possède tout le confort nécessaire, tout en conservant
                le cachet de l’ancien.
                Il est sur deux niveaux :
            </p>
                <span>Au rez de chaussée :</span> 
            <p>
                Une salle de séjour avec coin cuisine équipé de tout le matériel utile à votre séjour, vaisselle, batterie de cuisine
                ainsi que cuisinière avec four électrique, lave vaisselle, micro onde, réfrigérateur avec congélateur. En effet j'habite
                à 25 minutes en voiture des plus proches commerces et il est donc important de pouvoir prévoir quelques courses à
                l’avance. Toilettes séparées et salle d’eau avec douche et lavabo. Un lave linge est également à votre disposition.
            </p>
                <span> À l’étage :</span> 
            <p>
                Deux chambres et toilettes séparées : une chambre avec un lit double (160X200), et une chambre avec un
                lit double (160X200)et trois lits simples (90X 190).
            </p>
        </div>
    </div>
    <div class="carousel">
        <ngb-carousel *ngIf="assets">
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img class="rounded" [src]="assets[0]" alt="">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img class="rounded" [src]="assets[1]" alt="">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img class="rounded" [src]="assets[2]" alt="">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img class="rounded" [src]="assets[3]" alt="">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img class="rounded" [src]="assets[4]" alt="">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img class="rounded" [src]="assets[5]" alt="">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img class="rounded" [src]="assets[6]" alt="">
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
</div>