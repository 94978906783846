<div class="body background-grey">
    <div class="description">
            <div class="mobile">
                <img src="assets/fayard/entree.jpeg"  class="rounded" alt="Entree Fayard">
            </div>
        <div class="firstPara">
            <p>
                Tout comme son voisin le Frêne, le gîte du Fayard,
                d’une superficie d’environ 60m²,
                entièrement rénové par nos soins,
                conserve son charme d’antan avec son carrelage d’origine.<br />
                Il est sur deux niveaux et se compose :<br />
                <span> Au rez de chaussée :</span> <br />
                D’un grand séjour avec coin cuisine équipé de tout le matériel utile à votre séjour, vaisselle, batterie de cuisine
                ainsi que cuisinière avec four électrique, lave vaisselle, micro onde, réfrigérateur avec freezer, et d’un coin repas.
                Pour les repas de groupe, une deuxième table peut être rajoutée à votre demande. Un lave linge est également à votre
                disposition <br />
                <span>A l’étage :</span><br />
                Deux chambres: une chambre avec un lit double (140X190), et une chambre avec quatre lits simples
                superposés (90X200). Une salle d’eau avec douche, lavabo et WC
            </p>
        </div>
    </div>
    <div class="carousel">
        <ngb-carousel *ngIf="fayard">
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img class="rounded" [src]="fayard[0]" alt="">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img class="rounded"  [src]="fayard[1]" alt="">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img class="rounded"  [src]="fayard[2]" alt="">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img class="rounded" [src]="fayard[3]" alt="">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img class="rounded" [src]="fayard[4]" alt="">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img class="rounded" [src]="fayard[5]" alt="">
                </div>
            </ng-template>
            <ng-template ngbSlide>
                <div class="picsum-img-wrapper">
                    <img class="rounded" [src]="fayard[6]" alt="">
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
</div>