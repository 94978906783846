<div class="container-fluid p-0">
    <div class="headBackground" >
        <div id="head">   
            <div id="home mt-5">
                <div class="title my-5">
                    <h1><a class="valcaris" href="/" >valcaris</a></h1>
                </div>
            </div>
            <div class="arrow mt-5"><a href="#gites"> <i class="fas fa-angle-down"></i> </a> </div>
            <div class="about rounded" style="margin-top: 20%;" [@scrollAnimation]="state">
                <p>
                    Les Gîtes VALCARIS se situent au cœur des Balcons sud du Vercors, au sein du Parc Naturel Régional du Vercors.
                </p>
                <p>
                    Dans un écrin de verdure, à mi-chemin du Pays de la Gervanne et du Pays du Royans, à 980m d’altitude, je vous
                    accueille dans une ancienne ferme du XVIIème, entièrement rénovée.
                </p>
                <p>
                    Vous profiterez d’une nature sauvage, idéale pour vous ressourcer, randonner, voire herboriser.
                </p>
                <p>
                    Vous pourrez vous fondre dans une faune et une flore naturellement abondantes. <br />
                </p>
                <p>
                    Si vous en faites la demande par avance, vous pourrez envisager, des ballades avec ânes.
                </p>
                <p>
                    Je pourrai également vous concocter, aux beaux jours, des itinéraires de randonnées pédestres ou à VTT, vous
                    indiquer
                    les superbes sites d’escalade ou de canyoning qui nous entourent, et dès que la neige arrive les possibilités de
                    ski
                    nordique ou de raquettes
                </p>
            </div>
        </div>
    </div>
    <div class="container-fluid p-0 background-theme-light">
        <div class="gitesContainer" id="gites">
            <div class="gites">
                <h2 class="color-theme mb-5 ">Les Gîtes</h2>
                <mat-tab-group class="my-5">
                    <mat-tab label="Le Fayard">
                        <div class="fayard my-5">
                            <div class="fayardDiv">
                                <img class="fayardPicture rounded mr-n5" src="/assets/fayard/entree.jpeg" alt="entree"
                                    routerLink="/fayard">
                            </div>
                            <div class="descriptionFayard mx-auto p-4" routerLink="/fayard" (mouseenter)="onMouseEnter()"
                                (mouseleave)="onMouseLeave()" [style.opacity]="homeService.changeOfState">
                                <div>
                                    <h3 class="color-theme">Le Fayard</h3>
                                    <hr>
                                    <p>
                                        Tout comme son voisin le Frêne, le gîte du Fayard, d’une superficie d’environ 60m²,
                                        entièrement rénové par nos soins,
                                        conserve son charme d’antan avec son carrelage d’origine.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="infoMedia" routerLink="/fayard">
                            Cliquez pour plus d'information !
                        </div>
                    </mat-tab>
                    <mat-tab label="Le Frène">
                        <div class="frene my-5">
                            <div class="freneDiv">
                                <img class="frenePicture rounded mr-n5" src="/assets/frene/entree.jpg" alt="" routerLink="/frene">
                            </div>
                            <div class="descriptionFrene mx-auto p-4" routerLink="/frene" (mouseenter)="onMouseEnter()"
                                (mouseleave)="onMouseLeave()" [style.opacity]="homeService.changeOfState">
                                <h3 class="color-theme">Le Frene</h3>
                                <hr>
                                <p>
                                    Le gîte du Frêne fait partie avec son voisin le Fayard d’un corps de bâtiment proche de
                                    notre
                                    propre
                                    habitation.
                                    il possède tout le confort nécessaire, tout en conservant le cachet de l’ancien.
                                </p>
                            </div>
                        </div>
                        <div class="infoMedia">
                            Cliquez pour plus d'information !
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        <div class="" style="height: 20px;"></div>
        <div class="presentationContainer background-theme">
            <div class="presentation">
                <div class="first ">
                    <div class="pictureDiv ml-4 mt-5">
                        <img class="firstPicture rounded" src="/assets/firstPicture.jpg" alt="montagne">
                    </div>
                    <div class="firstParagraph">
                        <p>
                            Au départ de mes gîtes, différentes randonnées peuvent vous être proposées pour partir à la
                            découverte
                            d’orchis, de la flore et faune sauvage, à travers les sentiers balisés du parc et du GR.
                        </p>
                        <p>
                            Ces différentes randos peuvent se faire à pied, accompagnées ou non d’un âne équipé d’un bât.
                        </p>
                        <p>
                            Mes deux gîtes, non fumeurs, communiquent si nécessaire (gîte de groupe) par le rez de chaussée et vous offrent
                            tous les
                            deux un espace extérieur avec table et banc de jardin, afin de profiter du silence, de l’air et du
                            soleil de
                            nos montagnes.
                        </p>
                        <p>
                            A votre demande, je tiens à votre disposition un barbecue.
                        </p>
                    </div>
                </div>
                <div class="second ">
                    <div class="pictureDiv mr-4 mt-5">
                        <img class="secondPicture rounded" src="/assets/secondPicture.jpg" alt="forest">
                    </div>
                    <div class="secondParagraph">
                        <p>
                            Mes gîtes sont isolés ainsi que vous le montre cette photo. Il vous faudra donc envisager les
                            courses
                            nécessaires par avance : à votre disposition frigo et congélateur pour votre confort.
                        </p>
                        <p>
                            Cependant vous pourrez également vous faire plaisir et profiter des auberges sises à Léoncel, face à l’abbaye
                            cistercienne, ou encore au col de la Bataille à moins de 5 minutes de nos gîtes
                        </p>
                        <p>
                            Pour les groupes, si vous en faites la demande par avance, je pourrai vous faire profiter de ma cuisine où je privilégie
                            les produits des fermes les plus proches (fromages de Léoncel, viande de la Vacherie) et les circuits courts.
                        </p>
                        <p>
                            J'accepte les animaux, chiens chats et même tortues, mais nos amis à quatre pattes ne sont pas
                            autorisés dans les étages.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="carousel mt-5">
            <ngb-carousel *ngIf="random">
                <ng-template ngbSlide>
                    <div class="picsum-img-wrapper">
                        <img class="rounded"[src]="random[0]" alt="Random first slide">
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="picsum-img-wrapper">
                        <img class="rounded" [src]="random[1]" alt="Random second slide">
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="picsum-img-wrapper">
                        <img class="rounded" [src]="random[2]" alt="Random third slide">
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="picsum-img-wrapper">
                        <img class="rounded" [src]="random[3]" alt="Random third slide">
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="picsum-img-wrapper">
                        <img class="rounded" [src]="random[4]" alt="Random third slide">
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="picsum-img-wrapper">
                        <img class="rounded" [src]="random[5]" alt="Random third slide">
                    </div>          
                </ng-template>
                <ng-template ngbSlide>
                    <div class="picsum-img-wrapper">
                        <img class="rounded" [src]="random[6]" alt="Random third slide">
                    </div>
                </ng-template>
                <ng-template ngbSlide>
                    <div class="picsum-img-wrapper">
                        <img class="rounded" [src]="random[7]" alt="Random third slide">
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>
    </div>   
</div>