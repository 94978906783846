<nav class="navbar navbar-expand-lg navbar-light" id="up">
    <div class="container p-0">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#headerNavigation"
            aria-controls="headerNavigation" aria-expanded="false" aria-label="Toggle navigation">
            <span (click)="isCollapsed = !isCollapsed">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-menu color-theme">
                    <line x1="3" y1="12" x2="21" y2="12"></line>
                    <line x1="3" y1="6" x2="21" y2="6"></line>
                    <line x1="3" y1="18" x2="21" y2="18"></line>
                </svg>
            </span>
        </button>
        <div [ngbCollapse]="isCollapsed" class="collapse navbar-collapse nav navbar-nav " id="headerNavigation">
            <ul class="nav navbar-nav">
                <li class="nav-item">
                    <a class="nav-link mx-5" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" data-hover="Accueil" routerLink="/"
                        (click)="isCollapsed = !isCollapsed">Accueil</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link mx-5" [routerLinkActive]="['active']" data-hover="Le Fayard" routerLink="/fayard"
                        (click)="isCollapsed = !isCollapsed">Le Fayard</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link mx-5" [routerLinkActive]="['active']" data-hover="Le Frêne" routerLink="frene"
                        (click)="isCollapsed = !isCollapsed">Le Frêne</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link mx-5" [routerLinkActive]="['active']" data-hover="Tarifs" routerLink="tarifs"
                        (click)="isCollapsed = !isCollapsed">Tarifs</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link mx-5" [routerLinkActive]="['active']" data-hover="Contact" routerLink="/contact"
                        (click)="isCollapsed = !isCollapsed">Contact</a>
                </li>
            </ul>
        </div>
    </div>
</nav>
<div class="up" *ngIf="upArrow">
    <a href="#up">
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-arrow-up-circle color-theme">
            <circle cx="12" cy="12" r="10"></circle>
            <polyline points="16 12 12 8 8 12"></polyline>
            <line x1="12" y1="16" x2="12" y2="8"></line>
        </svg>
    </a>
</div>
