import { Component, OnInit } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
export interface PetitGroupe {
  nuits: string;
  one: string;
};
export interface Groupe{
  nuits: string;
  one: string;
};
export interface Info{
  service: string;
  prix: string;
}
export interface Mobile{
  nuits: string;
  one: string;
}
const dataPetitGroupe: PetitGroupe[] = [
  { nuits: 'Minimum deux nuits',one: '35€/nuit/personne'},

];
const dataGroupe: Groupe[] = [
  { nuits: 'Minimum deux nuits',one: '410€/total'},
];
const dataInfo : Info[] = [
  { service :  'Utilisation du lave linge', prix: '5 euros '},
  { service: 'Utilisation du sèche linge', prix: '5 euros ' },
  { service: 'Animaux admis, mais pas à l\'étage ou dans les chambres', prix: '2 euros/ jour'},
  { service: 'A disposition chez nous des boissons (Coca-Cola, orangina, schweppes, bière) en 25 ou 33 cl', prix:'2,50 euros / boisson'},
  {service: 'Bière artisanale (33cl) blond, blanche et ambrée', prix: '3,50 euros'},
  {service: 'Fer à repasser, sèche-cheveux, lit à barreau', prix:'Gratuit'}
]

const mobileSmallGroup: Mobile[]= [
  { nuits: 'Minimum deux nuits',one: '35€/nuit/personne'},
]
const mobileBigGroup: Mobile[]= [
  { nuits: 'Minimum deux nuits',one: '410€/total'},
]
@Component({
  selector: 'app-tarifs',
  templateUrl: './tarifs.component.html',
  styleUrls: ['./tarifs.component.scss']
})
export class TarifsComponent implements OnInit {
  displayedColumns: string[] = ['nuits','one'];
  displayedColumnsMobile: string[] = ['nuits','one'];
  dataSource = dataPetitGroupe;
  dataMobile = mobileSmallGroup;
  dataMobileBig = mobileBigGroup;
  columns: string[] = ['nuits', 'euros'];
  data = dataGroupe;
  columnInfo: string[] = ['service', 'prix'];
  dataInfo= dataInfo;
  constructor() { }

  ngOnInit() {
  }

}
