<div class="cards"> 
    <div class="photo">
        <mat-card class="example-card">
            <mat-card-header>
            </mat-card-header>
            <img mat-card-image src="assets/random/random1.jpg" >
        </mat-card>
    </div>
    <div class="photo">
        <mat-card class="example-card">
            <mat-card-header>
            </mat-card-header>
            <img mat-card-image src="assets/random/random2.jpg">    </mat-card>
    </div>
    <div class="photo">
        <mat-card class="example-card">
            <mat-card-header>
            </mat-card-header>
            <img mat-card-image src="assets/random/random3.jpg">    </mat-card>
    </div>
    <div class="photo">
        <mat-card class="example-card">
            <mat-card-header>
            </mat-card-header>
            <img mat-card-image src="assets/random/random4.jpg">    </mat-card>
    </div>
    <div class="photo">
        <mat-card class="example-card">
            <mat-card-header>
            </mat-card-header>
            <img mat-card-image src="assets/random/random5.jpg">
        </mat-card>
    </div>
    <div class="photo">
        <mat-card class="example-card">
            <mat-card-header>
            </mat-card-header>
            <img mat-card-image src="assets/random/random6.jpg">
        </mat-card>
    </div>
    <div class="photo">
        <mat-card class="example-card">
            <mat-card-header>
            </mat-card-header>
            <img mat-card-image src="assets/random/random8.jpg">
        </mat-card>
    </div>
</div>
