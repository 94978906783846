<div class="container-fluid background-grey my-5" style="min-height: 650px;">
    <div class="container pt-2 desktop">
        <mat-tab-group>
            <mat-tab label="Gîte (max 6 personnes)">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                    <ng-container matColumnDef="nuits">
                        <th mat-header-cell *matHeaderCellDef> Nuits </th>
                        <td mat-cell *matCellDef="let element"> {{element.nuits}} </td>
                    </ng-container>
                    <ng-container matColumnDef="one">
                        <th mat-header-cell *matHeaderCellDef> Tarif </th>
                        <td mat-cell *matCellDef="let element"> {{element.one}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </mat-tab>
            <mat-tab label="Gîte de groupe (max 15 pers.)">
                <table mat-table [dataSource]="data" class="mat-elevation-z8">
                    <ng-container matColumnDef="nuits">
                        <th mat-header-cell *matHeaderCellDef> Nuits </th>
                        <td mat-cell *matCellDef="let element"> {{element.nuits}} </td>
                    </ng-container>
                    <ng-container matColumnDef="one">
                        <th mat-header-cell *matHeaderCellDef> 8 personnes </th>
                        <td mat-cell *matCellDef="let element"> {{element.one}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </mat-tab>
            <mat-tab label="Autres Informations">
                <table mat-table [dataSource]="dataInfo" class="mat-elevation-z8">
                    <ng-container matColumnDef="service">
                        <th mat-header-cell *matHeaderCellDef> Service </th>
                        <td mat-cell *matCellDef="let e"> {{e.service}} </td>
                    </ng-container>
                    <ng-container matColumnDef="prix">
                        <th mat-header-cell *matHeaderCellDef> Prix </th>
                        <td mat-cell *matCellDef="let e"> {{e.prix}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columnInfo"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnInfo;"></tr>
                </table>
            </mat-tab>
        </mat-tab-group>
    </div>
    <!--Table for mobile-->
    <div class="p-0 mobile">
        <mat-tab-group>
            <mat-tab label="Gîte < 6 pers">
                <table mat-table [dataSource]="dataMobile" class="mat-elevation-z8">
                    <ng-container matColumnDef="nuits">
                        <th mat-header-cell *matHeaderCellDef> Nuits </th>
                        <td mat-cell *matCellDef="let element"> {{element.nuits}} </td>
                    </ng-container>
                    <ng-container matColumnDef="one">
                        <th mat-header-cell *matHeaderCellDef> 1 personne </th>
                        <td mat-cell *matCellDef="let element"> {{element.one}}</td>
                    </ng-container>
                    <ng-container matColumnDef="two">
                        <th mat-header-cell *matHeaderCellDef> Personne supplémentaire </th>
                        <td mat-cell *matCellDef="let element">{{element.two}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsMobile"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsMobile;"></tr>
                </table>
            </mat-tab>
            <mat-tab label="Gîte < 15 pers ">
                <table mat-table [dataSource]="dataMobileBig" class="mat-elevation-z8">
                    <ng-container matColumnDef="nuits">
                        <th mat-header-cell *matHeaderCellDef> Nuits </th>
                        <td mat-cell *matCellDef="let element"> {{element.nuits}} </td>
                    </ng-container>
                    <ng-container matColumnDef="one">
                        <th mat-header-cell *matHeaderCellDef>8 persoones</th>
                        <td mat-cell *matCellDef="let element"> {{element.one}}</td>
                    </ng-container>
                    <ng-container matColumnDef="two">
                        <th mat-header-cell *matHeaderCellDef> Personne supplémentaire </th>
                        <td mat-cell *matCellDef="let element">{{element.two}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsMobile"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsMobile;"></tr>
                </table>
            </mat-tab>
            <mat-tab label="Autres Informations">
                <table mat-table [dataSource]="dataInfo" class="mat-elevation-z8">
                    <ng-container matColumnDef="service">
                        <th mat-header-cell *matHeaderCellDef> Service </th>
                        <td mat-cell *matCellDef="let e"> {{e.service}} </td>
                    </ng-container>
                    <ng-container matColumnDef="prix">
                        <th mat-header-cell *matHeaderCellDef> Prix </th>
                        <td mat-cell *matCellDef="let e"> {{e.prix}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columnInfo"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnInfo;"></tr>
                </table>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="container py-5 justify-content-center">
        Compte tenu des circonstances sanitaires actuelles le ménage vous sera facturé 40€. <br>
        Ces tarifs s'entendent avec : <br>
        Taxe de séjour incluse. Toutes charges comprises. <br>Lits faits ; nous nous occupons de défaire
        les lits en fin de séjour. <br>
        A charge des occupants : Poubelles vidées (tri sélectif). Lave-vaisselle vidé ou vaisselle faite. <br>
        Arrivée – départ : Arrivée le premier jour à partir de 15h dernier jour avant 10h30. Caution : Chèque de 300€
    </div>
</div>
